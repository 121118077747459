import ContentRowTop from "./ContentRowTop";

function ContentWrapper() {
  return (
    <div id="content-wrapper" className="d-flex flex-column">
      <div id="content">
        <ContentRowTop />
      </div>
    </div>
  );
}
export default ContentWrapper;
